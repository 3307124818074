@font-face {
  font-family: Logo;
  src: local("./fonts/Satisfy/Satisfy-Regular.ttf"), url("./fonts/Satisfy/Satisfy-Regular.ttf");
}

.App-logo {
  color: #5b5bff;
  font-family: Logo;
}

.elevate {
  box-shadow: 0 2px 6px rgb(0 0 0 / 0.2);
}

button {
  border-radius: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-group-item.active {
  background-color: #5b5bff;
}

.switch-on {
  background-color: #5b5bff !important;
  border-color: #5b5bff !important;
}

.dropdown-item.active {
  background: #5b5bff !important;
}

.switch-handle {
  border-color: #5b5bff !important;
}

.spinner-border.text-success {
  color: #5b5bff !important   
}

.sidebar-link:hover {
  background: #E6E9EB !important
}

.container-fluid {
  max-width: 1550px;
}

.w-md-editor {
  background: white !important;
  box-shadow: none !important;
  border: 0;
}

.wmde-markdown-color {
  background: white !important
}

.w-md-editor p,
li,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  background: white !important;
  color: black
}

.w-md-editor-toolbar {
  background: white !important;
}

.w-md-editor-toolbar svg {
  color: #5b5bff
}

.w-md-editor-toolbar {
  border-bottom: 1px solid grey !important;
}

.w-md-editor-preview {
  box-shadow: inset 1px 0 0 0 grey !important;
}

.nav-pills .nav-link.active {
  background-color: #5b5bff !important;
  color: white !important
}

.nav-pills .nav-link {
  color: #5b5bff
}

.nav-pills {
  border-bottom: .5px solid rgb(192, 192, 192);
  padding-bottom: 1rem;
}

.card {
  border-radius: 0 !important;
}